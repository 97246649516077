/* Font styles */
@font-face {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSans-Bold";
  font-style: bold;
  src: url("../assets/fonts/OpenSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSans-Light";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/OpenSans-Light.ttf") format("truetype");
}

.regular {
  font-family: var(--ion-font-family-regular) !important;
}

.bold {
  font-weight: bold !important;
  font-family: var(--ion-font-family-bold) !important;
}

.light {
  font-weight: 300 !important;
  font-family: var(--ion-font-family-light) !important;
}

.italic {
  font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--ion-font-family-bold);
}

.head1 {
  font-size: 34px !important;
  line-height: 40px !important;
  letter-spacing: normal;
}

.head2 {
  font-size: 28px !important;
  line-height: 34px !important;
  letter-spacing: normal;
}

.head3 {
  font-size: 24px !important;
  line-height: 31px !important;
  letter-spacing: normal;
}

.head4 {
  font-size: 22px !important;
  line-height: 29px !important;
  letter-spacing: normal;
}

.body1 {
  font-size: 19px !important;
  line-height: 25px !important;
  letter-spacing: normal;
}

.body2 {
  font-size: 17px !important;
  line-height: 22px !important;
  letter-spacing: normal;
}

.caption1 {
  font-size: 15px !important;
  line-height: 20px !important;
  letter-spacing: normal;
}

.caption2 {
  font-size: 13px !important;
  line-height: 17px !important;
  letter-spacing: normal;
}

ion-button,
ion-button > * {
  font-family: var(--ion-font-family-bold);
  font-size: 15px;
  letter-spacing: 0.44px;
}
