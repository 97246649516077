.center {
  display: flex;
  justify-content: center;
  text-align: center;
}

.horizontal-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  // -ms-transform: translateY(-50%);
  // transform: translateY(-50%);
}

.circle {
  border-radius: 50%;
}

.rounded {
  border-radius: 5px;
}

/* Toolbar transparentes con un degradado de gris -> transparente desde arriba en vertical para visualizar botones de color claro */
.transparent-toolbar {
  --background: linear-gradient(to bottom, black, transparent);
  --ion-color-base: transparent !important;
  ion-button,
  ion-back-button {
    --color: white;
  }
}

.bottom-sheet {
  border-radius: 5% 5% 0 0;
}

// a-links without state (active, visited, ...) colors
a {
  color: var(--ion-color-primary);
  text-decoration: none;
}

mat-form-field {
  width: 100%;
}

.disabled {
  filter: grayscale(1);

  // CHANGE: dificulta la lectura de los textos
  /* &:not(ion-label) {
    opacity: 0.4;
  } */
}
