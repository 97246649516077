:root {
  /** Color 0 **/
  --ion-color-color-0: #f28b74;
  --ion-color-color-0-rgb: 242, 139, 116;
  --ion-color-color-0-contrast: #000000;
  --ion-color-color-0-contrast-rgb: 0, 0, 0;
  --ion-color-color-0-shade: #d57a66;
  --ion-color-color-0-tint: #f39782;

  /** Color 1 **/
  --ion-color-color-1: #5975b6;
  --ion-color-color-1-rgb: 89, 117, 182;
  --ion-color-color-1-contrast: #ffffff;
  --ion-color-color-1-contrast-rgb: 255, 255, 255;
  --ion-color-color-1-shade: #4e67a0;
  --ion-color-color-1-tint: #6a83bd;

  /** Color 2 **/
  --ion-color-color-2: #7fdbd5;
  --ion-color-color-2-rgb: 127, 219, 213;
  --ion-color-color-2-contrast: #000000;
  --ion-color-color-2-contrast-rgb: 0, 0, 0;
  --ion-color-color-2-shade: #70c1bb;
  --ion-color-color-2-tint: #8cdfd9;

  /** Color 3 **/
  --ion-color-color-3: #277c96;
  --ion-color-color-3-rgb: 39, 124, 150;
  --ion-color-color-3-contrast: #ffffff;
  --ion-color-color-3-contrast-rgb: 255, 255, 255;
  --ion-color-color-3-shade: #226d84;
  --ion-color-color-3-tint: #3d89a1;
}

$blisterColors: ("color-0", "color-1", "color-2", "color-3");

@each $color in $blisterColors {
  .ion-color-#{$color} {
    --ion-color-base: var(--ion-color-#{$color}) !important;
    --ion-color-base-rgb: var(--ion-color-#{$color}-rgb) !important;
    --ion-color-contrast: var(--ion-color-#{$color}-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-#{$color}-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-#{$color}-shade) !important;
    --ion-color-tint: var(--ion-color-#{$color}-tint) !important;
  }
}
