/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Typography */
@import "./theme/typography.scss";
/* Ionic components override */
@import "./theme/ionic.override.scss";
/* Angular Material override */
@import "./theme/material.override.scss";
/* Zero custom styles */
@import "./theme/zero.styles.scss";

/* App Custom Colors */
@import "./theme/custom.colors.scss";

.header-logo {
  width: 86px;
  height: 40px;
}

ion-button {
  height: 3em !important;
  width: auto;
  --padding-start: 2em !important;
  --padding-end: 2em !important;
}

button {
  height: 3em;
  padding: 0 2em !important;

  &.mat-flat-button {
    margin: 0 !important;
  }

  .mat-button-wrapper {
    @extend .body2;
  }
}
