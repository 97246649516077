/* Ionic back button size large */
/* ion-back-button {
  --icon-font-size: xx-large;
} */

// Para no transformar el texto de los botones en MAYÚS.
ion-button {
  text-transform: none !important;
  --border-radius: 2px;
}

// Comptabilidad con el padding del "safe-area-top"
ion-header {
  ion-toolbar {
    padding: 0 !important;
  }
}

// Default grid>row>col padding = 0
ion-grid {
  padding: 0;
}
ion-row {
  padding: 0;
  ion-col {
    padding: 0;
  }
}

/*
  Linea del borde inferior de la tab (en modadlidad de pestaña superior) de la pestaña activa
  Para hacer el ancho de la línea adaptativa, cambia el ".tab-selected.width" por un porcentaje
*/
ion-tab-bar[slot="top"] {
  height: 36px;
  ion-tab-button {
    color: rgba(var(--ion-color-contrast-rgb), 0.5);
  }
  .tab-selected {
    // border-bottom: 4px solid var(--ion-color-primary);
    &::after {
      display: block;
      content: "";
      margin: -4px auto;
      width: 20px;
      height: 4px;
    }
  }
}

// ion-item classes
ion-item {
  &.transparent-item {
    --background: transparent;
    background: transparent;
  }
  &.no-horizontal-padding {
    --padding-start: 0 !important;
    --padding-end: 0 !important;
  }

  &.icon-label-item {
    --padding-start: 0;
    --padding-end: 0;
    ion-icon {
      margin-right: 8px;
    }
    /* ion-label,
    ion-note {
      padding-bottom: 8px;
    } */
  }
}

.no-ripple {
  --ripple-color: transparent;
}

.transparent {
  --background: transparent !important;
  background: transparent !important;
}
